<template>
  <v-row dense class="overflow-y-hidden">
    <v-row>
      <v-dialog v-model="editEmailDialog" style="border: 100px white solid" max-width="400px"  transition="dialog-top-transition">
        <v-card class="pt-4 px-2 pb-2">
          <v-card-title class="d-flex justify-center align-center">
            <v-row  dense>
              <v-col class="py-0 mb-1" cols="12">
                <span class="icon-background">
                  <v-icon dense color="white" style="font-size: 20px" class="my-0">mdi-pencil</v-icon>
                </span>
              </v-col>
              <v-col cols="12">
                <span>{{ $t('areYouSure') }}</span>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text class="text-center pb-1">{{ $t('editEmailSure') }}</v-card-text>
          <v-card-actions class="mb-2">
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                    v-if="patientEmailChange"
                    outlined
                    dense
                    value="patientEmailChange.id"
                    hide-details
                    v-model="patientEmailChange.email"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-btn class="save-button-style" @click="editInvalidEmail">{{ $t("save") }}</v-btn>
              </v-col>
              <v-col cols="6">
                <v-btn  outlined depressed class="cancel-button-style" block @click="closeEditEmailDialog">{{ $t("cancel") }}</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="confirmDialog" max-width="400px" transition="dialog-top-transition" persistent>
        <v-card>
          <v-card-title class="mx-auto">{{$t('change')}} {{$t('status')}}</v-card-title>
          <v-card-text class="pb-2">{{$t('changeStatusQuestion')}}</v-card-text>
          <v-card-actions class="mx-auto">
            <v-btn @click="confirmToggleStatus" color="primary2" class="text-color-white">{{ $t('yes') }}</v-btn>
            <v-btn @click="cancelToggleStatus">{{ $t('no') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-col cols="12">
      <v-data-table
          :loading="loading"
          :headers="headers"
          :no-data-text="$t('no-data-available')"
          :loading-text="$t('loading')"
          :headers-length="headers.length"
          :search="appBarSearch"
          :items="filteredUsers2"
          :items-per-page="-1"
          sort-by="id"
          item-key="id"
          fixed-header
          dense
          :height="pageHeight(150)"
          class="ma-3 medical-staff-data-table"
          :footer-props="{
          showCurrentPage: false,
          showFirstLastPage: false,
          itemsPerPageOptions: [filteredUsers2.length],
          itemsPerPageText: null
        }">
        <template v-slot:item="{ item }">
          <tr @click="userDetails(item)">
            <td class="pl-2">
              <v-row dense class="d-flex align-center py-1">
                <v-col class="text-left" cols="10">
                  <v-row dense>
                    <span  class="font-bold mr-10 px-2">{{ item.fullname }}</span>
                  </v-row>
                  <v-row dense>
                    <span class="px-2">{{ item.sex }}</span>
                  </v-row>
                </v-col>
              </v-row>
            </td>
            <td class="text-left pl-0">
              <span >{{ item.patientMetadata?.medicalRegistrationNumber }}</span>
            </td>
            <td class="text-left px-0">
              <span >{{ item.age }}</span>
            </td>
            <td class="pl-0">
              <v-row dense>
                <v-col class="d-flex align-center" cols="11">
                  <span>{{ item.email }}</span>
                </v-col>
              </v-row>
            </td>
            <td class="pl-0">
              <v-row dense>
                <v-col class="d-flex align-center" cols="11">
                  <span>{{ item.phone }}</span>
                </v-col>
              </v-row>
            </td>
            <td class="pl-0">
              <v-row v-if="item.status !==-1  && item.status !==-99  && item.status !==10  ">
                <v-switch  readonly  @click.stop="setUserStatus(item)"  v-model="item.status" class="mt-n1 ml-3" hide-details></v-switch>
              </v-row>
            </td>
            <td v-if="roleName === 'HOSPITAL_ADMIN' || roleName === 'DOCTOR' || roleName === 'NURSE'" class="pr-0">
              <div v-if="item.status !== 0" :disabled="!item.hasMedicalTeam">
                <div v-if="item.status !== 1" class="text-left">
                  <v-tooltip left>
                    <template v-slot:activator="{ on }">
                      <v-icon size="27" v-on="on" @click.stop="sendActivationEmail(item)" color="primary">
                        mdi-email
                      </v-icon>
                    </template>
                    <span>{{ $t('validateEmail') }}</span>
                  </v-tooltip>
                </div>
                <div v-else>
                  <v-tooltip left>
                    <template v-slot:activator="{on}">
                      <v-icon class="float-left" @click.stop="sendActivationEmail(item)" color="primary" size="27" v-on="on">mdi mdi-lock-reset</v-icon>
                    </template>
                    <span>{{$t('send-reset-email')}}</span>
                  </v-tooltip>
                </div>
              </div>
            </td>
            <td>
              <v-icon class="col-right-alignment"  small  :style="item.status === 1 ? 'color: #09CB09' : ''">mdi-checkbox-blank-circle</v-icon>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { videocallBlack, mailPurple, phonePurple, closeBlack } from '@/assets';
import { AsYouType } from 'libphonenumber-js';
import { showSuccessAlert, showErrorAlert } from '@/utils/utilities';

export default {
	data () {
		return {
			search: '',
      confirmDialog: false,
      hideSwitch: false,
      userToToggle: null,
			loading: true,
      editEmailDialog: false,
      closeBlack: closeBlack,
      dialog: false,
      patientEmailChange: '',
			videocallBlack: videocallBlack,
			mailPurple: mailPurple,
			phonePurple: phonePurple,
		};
	},
	computed: {
		...mapState({
			userData: (state) => state.authentication.userData,
			relatedPatients: (state) => state.hospitals.relatedPatients,
			verifiersFromRepo: (state) => state.verifiers.verifiers,
		}),
		...mapGetters({
      usersActivity: 'filterbar/getUsersActivity',
      roleName: 'authentication/getRole',
			hospitalId: 'authentication/getHospitalId',
			pageHeight: 'pageHeight',
			delay: 'authentication/getDelay',
			appBarSearch: 'filterbar/getSearch',
			appBarUserStatusVerifier: 'filterbar/getUserStatusVerifier',
      removeSearch: 'filterbar/removeSearch',
		}),
		headers () {
			const headers = [
				{ text: this.$t('name'), value: 'fullname',	class: 'table-header', align: 'start pl-3' },
				{ text: this.$t('header-medical-no'), value: 'patientMetadata.medicalRegistrationNumber', class: 'table-header' },
				{ text: this.$t('header-age'), value: 'age', class: 'table-header' },
				{ text: this.$t('header-email'), value: 'email', class: 'table-header' },
				{ text: this.$t('header-phone'), value: 'phone', class: 'table-header' },
        { text: this.$t('set-status'), value: 'status', class: 'table-header' },
				{ text: this.$t('actions'), value: 'emailReset', class: 'table-header', sortable: false, align: 'start pl-2' },
				{ text: this.$t('status'), value: 'status', class: 'table-header', sortable: false, align: 'center' },
			];
			return headers;
		},
    filteredUsers2 () {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.loading = true;
      // let calculateFilteredPatients = this.filteredUsers || [];
      let calculateFilteredPatients = this.relatedPatients || [];

      if (this.appBarUserStatusVerifier !== -99 && this.appBarUserStatusVerifier !== null) {
        calculateFilteredPatients = calculateFilteredPatients.filter(u => u.status === this.appBarUserStatusVerifier);
      }
      if (this.usersActivity) {
        // Show active users (status 1)
        calculateFilteredPatients = calculateFilteredPatients.filter(u => u.status === 1);
      } else {
        // Show inactive users (status -1) and other statuses 0:INACTIVE, 99:DECEISED
        calculateFilteredPatients = calculateFilteredPatients.filter(u => u.status !== 1);
        if (calculateFilteredPatients.filter(u => u.status === -1 || u.status === 99)) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.hideSwitch = true;
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.hideSwitch = false;
      }

      // One more filter that comes from the switch in the app bar for active and inactive users

      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.loading = false;
      return calculateFilteredPatients;
    },
	},
  created () {
    this.$store.dispatch('filterbar/usersActivity', true);
  },
  watch: {
    async relatedPatients (val) {
      // eslint-disable-next-line no-unused-expressions
      val?.forEach((user) => {
        user.fullname = user.firstName + ' ' + user.lastName;
        user.age = this.calculateAge(user.birthdate);
        user.sex = user.gender === 'M' ? this.$t('male') : this.$t('female');
        user.phone = new AsYouType().input(user.phone);
        if (user.hasMedicalTeam === true) {
          user.medTeam = 1;
        }
        user.patientHasDoneReadingForTodayValue = user.patientHasDoneReadingForToday === true ? this.$t('yes') : this.$t('no');
        this.verifiersFromRepo.forEach(v => {
          if (user.status === v.lowValue) {
            user.statusShortDescription = v.shortDescription;
            user.statusLongDescription = v.longDescription;
          }
        });
      });
    },
  },
  async mounted () {
    await this.getVerifiers();
    await this.getPatients();
  },
	methods: {
    isHiddenSwitch (status) {
      return status === -1 || status === 99 || status === 10;
    },
    async confirmToggleStatus () {
      const requestData = {
        userId: this.userToToggle.id,
        statusValueToSet: this.userToToggle.status ? 0 : 1,
      };
         this.$store.dispatch('users/setUserStatus', requestData).then(res => {
           this.confirmDialog = false;
           this.getPatients();
        });
    },

    cancelToggleStatus () {
      this.confirmDialog = false;
      this.userToToggle = null;
    },
    async setUserStatus (item) {
      this.userToToggle = item;
      this.confirmDialog = true;
    },
		calculateAge (dateString) {
			var today = new Date();
			var birthDate = new Date(dateString?.split('T')[0]);
			var age = today.getFullYear() - birthDate.getFullYear();
			var m = today.getMonth() - birthDate.getMonth();
			if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
				age--;
			}
			return age;
		},
    async userDetails (item) {
      if (!item.medTeam) {
        showErrorAlert(this.$t('medicalTeamCheck'));
        await this.$store.dispatch('filterbar/resetSearch');
       } else {
        await this.$store.dispatch('users/getUserById', item.id);
        await this.$router.push({
          name: 'Patient Details',
          params: { id: `${item.id}` },
        });
      }
      },
    editEmail (item) {
      this.editEmailDialog = true;
      this.patientEmailChange = item;
    },
    closeEditEmailDialog () {
      this.editEmailDialog = false;
    },
    editInvalidEmail () {
      const body = {
        userId: this.patientEmailChange.id,
        newEmailValue: this.patientEmailChange.email,
      };
      this.$store.dispatch('users/editInvalidEmail', body).then(async (res) => {
        if (res.data.resFlag) {
          showSuccessAlert(this.$t('success'));
          this.editEmailDialog = false;
        } else {
          showErrorAlert(this.$t('failed'));
        }
      });
    },
		async getPatients () {
      this.loading = true;
			const hospitalIdFromStore = this.userData.hospitalAssignedData.id;
			if (this.roleName === 'HOSPITAL_ADMIN' || this.roleName === 'DOCTOR' || this.roleName === 'NURSE') {
				await this.$store.dispatch('hospitals/getRelatedPatientsV3', hospitalIdFromStore)
					.then(() => {
            // eslint-disable-next-line no-unused-expressions
						this.relatedPatients?.forEach((user) => {
							user.fullname = user.firstName + ' ' + user.lastName;
							user.age = this.calculateAge(user.birthdate);
							user.sex = user.gender === 'M' ? this.$t('male') : this.$t('female');
							user.phone = new AsYouType().input(user.phone);
							if (user.hasMedicalTeam === true) {
								user.medTeam = 1;
							}
							user.patientHasDoneReadingForTodayValue = user.patientHasDoneReadingForToday === true ? this.$t('yes') : this.$t('no');
							this.verifiersFromRepo.forEach(v => {
								if (user.status === v.lowValue) {
									user.statusShortDescription = v.shortDescription;
									user.statusLongDescription = v.longDescription;
								}
							});
						});
						this.loading = false;
					});
			} else if (this.roleName === 'ORGANIZATION_ADMIN') {
				this.loading = false;
			} else {
				this.loading = false;
			}
		},
		async getVerifiers () {
			const header = {
				domainValue: 'USER_STATUS',
			};
			await this.$store.dispatch('verifiers/getVerifiersForDomain', header);
		},
		closeDialog () {
			this.dialog = false;
			this.getPatients();
		},
		success (res) {
			this.dialog = false;
			this.getPatients();
			if (res.resFlag) {
				this.$store.commit('alerts/add', {
					id: 'updatedStaffDetails',
					type: 'success',
					color: 'main_green',
					message: res.msg,
				});
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			} else {
				this.$store.commit('alerts/add', {
					id: 'updatedStaffDetails',
					type: 'error',
					color: 'main_red',
					message: res.msg,
				});
				this.$store.dispatch('alerts/clearWithDelay', this.delay);
			}
		},
		async sendActivationEmail (item) {
			const sendValidationEmail = { email: item.email };
			await this.$store.dispatch('users/sendValidationLinkByEmail', sendValidationEmail)
				.then(res => {
					if (res.resFlag) {
						this.$store.commit('alerts/add', {
							id: 'addedAdmin',
							type: 'success',
							color: 'main_green',
							message: res.msg,
						});
						this.$store.dispatch('alerts/clearWithDelay', this.delay);
					} else {
						this.$store.commit('alerts/add', {
							id: 'errorAddingAdmin',
							type: 'error',
							color: 'main_red',
							message: res.msg,
						});
						this.$store.dispatch('alerts/clearWithDelay', this.delay);
					}
				});
		},
	},
};
</script>
